import React from "react";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {Grid, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


interface Props {
    input? : any
    content: any[]
    pokerTheme: PokerTheme
}

const useStyles =
        makeStyles((theme: Theme) => ({
            panel: (pTheme: PokerTheme) => ({
                boxShadow: "0 0 10px",
                margin: "10px",
                textAlign: "center",
                color: asCssRGBAString(pTheme.panel.text),
                backgroundColor: asCssRGBAString(pTheme.panel.background),
                //minWidth:"fit-content",
                overflow:"hidden",
                position: "relative",
                padding: "10px",
            }),
            gridItem: {
                [theme.breakpoints.between("sm", "md")]: {
                    width: 500,
                },
                [theme.breakpoints.down("xs")]: {
                    width:500,
                },
                [theme.breakpoints.up("lg")]: {
                    width: "100%",
                }
            },
        }))

export const PokerPanel: React.FC<Props> = (props) => {
    const {
        input,
        content,
        pokerTheme,
    } = props
    const classes = useStyles(pokerTheme)

    return (
        <div className={classes.panel}>
            <Grid container spacing={1} justify="center">
                <Grid item>
                    {input}
                </Grid>
                {
                    content.map((c, index) =>
                        <Grid key={index} item className={classes.gridItem}>
                            {c}
                        </Grid>
                    )
                }
            </Grid>
        </div>
    )
}
