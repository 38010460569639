import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {PokerButton} from "./PokerButton";
import {PokerTheme} from "../../model/PokerTheme";
import {FormattedMessage, useIntl} from "react-intl";
import {
    Avatar,
    Button,
    Collapse,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {MessageTypes} from "../SnackbarComponents/MessageSnackbar";
import ControllerContext from "../../contexts/ControllerContext";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";
import Jimp from "jimp";
import blobToFile from "../../util/File";

export const AVATAR_HEIGHT_LIMIT = 48
export const AVATAR_WIDTH_LIMIT = 48
export const AVATAR_FILE_SIZE_LIMIT = 1000000

const useStyles = makeStyles((pokerTheme) =>
    createStyles({
    avatarPreview: {
        height: AVATAR_HEIGHT_LIMIT + "px",
        width: AVATAR_WIDTH_LIMIT + "px",
        marginRight: "0px",
        marginLeft: "auto"
    },
    contentContainer: {
        marginBottom: pokerTheme.spacing(2)
    }
}))

export const AvatarDialog: React.FC<{
    pokerTheme: PokerTheme,
    tableID: string,
    playerID: string,
    cognitoID: string | undefined,
    showSnackbarMessage: (messageType: MessageTypes, message: string) => void
}> = (props)  => {
    const {pokerTheme, playerID, cognitoID, tableID, showSnackbarMessage} = props
    const {mainController} = useContext(ControllerContext)
    const styles = useStyles(pokerTheme)
    const [showAvatarDialog, setShowAvatarDialog] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const defaultAvatar = pokerTheme.table.currentPlayerIcon.url
    const [imageLink, setImageLink] = useState<string>(defaultAvatar);
    const [currentAvatar, setCurrentAvatar] = useState<string>(defaultAvatar);
    const intl = useIntl()
    const {userAuthorized} = useContext(AuthorizationContext)

    useEffect(() => {
        const iconLink = cognitoID !== undefined && userAuthorized
            ? process.env.REACT_APP_AVATAR_S3_BUCKET + `/Registered_Users/${cognitoID}`
            : process.env.REACT_APP_AVATAR_S3_BUCKET + `/Guest_Users/${tableID}/${playerID}`
        fetch(iconLink).then(
            response => {
                const type = response.headers.get("Content-Type")
                if (type !== null && type.startsWith("image")) {
                    setImageLink(iconLink)
                    setCurrentAvatar(iconLink)
                }
            }
        )
    },[tableID, playerID, cognitoID, userAuthorized])

    const setCurrentlySelectedImage = (img: File | null) => {
        setImageFile(img)
        if (img !== null) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImageLink(reader.result as string)
            }
            reader.readAsDataURL(img)
        }
        else {
            setImageLink(currentAvatar)
        }
    }

    const handleCancel = () => {
        setShowAvatarDialog(false)
        setImageLink(currentAvatar)
        setImageFile(null)
    }

    const handleReset = () => {
        setImageLink(defaultAvatar)
        setImageFile(null)
    }

    const handleSave = () => {
        setShowAvatarDialog(false)
        const authorizedCognitoID = userAuthorized ? cognitoID : undefined
        if (imageFile !== null) {
            mainController.uploadAvatar(tableID, playerID, authorizedCognitoID, imageFile)
                      .catch((res) => showSnackbarMessage('warning', res.message))
        } else if (imageLink !== currentAvatar) {
            mainController.deleteAvatar(tableID, playerID, authorizedCognitoID)
                      .catch((res) => showSnackbarMessage('warning', res.message))
        }
        setCurrentAvatar(imageLink)
    }

    const handleUploadAvatar = (event: ChangeEvent<HTMLInputElement>) => {
        const img = event.target.files?.item(0)
        event.target.value = "" // required to reset chrome's input for same file selection after reset
        setErrorMessage(null)
        setImageFile(null)
        new Promise<File>((resolve, reject) => { // Image was selected
            if (img === undefined || img === null) {
                reject({id: "avatar_component-text-error_default"})
            }
            else {
                resolve(img)
            }
        }).then((pImg) => { // Image has correct file extension
            const type = pImg.name.toLowerCase().split(".").pop()
            const allowedTypes = ["png", "jpg", "jpeg"]
            if (type === undefined || !allowedTypes.includes(type)) {
                return Promise.reject({id: "avatar_component-text-error_type"})
            }
            return pImg
        }).then((pImg) => { // Image has correct file size
            if (pImg.size > AVATAR_FILE_SIZE_LIMIT) {
                return Promise.reject({id: "avatar_component-text-error_size"})
            }
            return pImg
        }).then((pImg) => editIfIncorrectDimensions(pImg) // image dimensions are adjusted
            .catch(() => Promise.reject({id: "avatar_component-text-error_default"}))
        ).then(
            (pImg) => setCurrentlySelectedImage(pImg),
            (reason) => setErrorMessage(intl.formatMessage(reason))
        )
    }

    const editIfIncorrectDimensions = async (file: File): Promise<File> => {
        const reader = new FileReader()
        return new Promise((resolve: (file: File) => void) => {
            reader.onloadend = () => {
                const result = reader.result
                if (typeof result === "string") {
                    Jimp.read(result)
                        .then(img => cropAndResize(img))
                        .then(img => img.getBase64Async(img.getMIME()))
                        .then(data => fetch(data))
                        .then(resp => resp.blob())
                        .then(blob => blobToFile(blob, "processedAvatar"))
                        .then(file => resolve(file))
                        .catch(() => {throw Error})
                } else {
                    throw Error
                }
            }
            reader.readAsDataURL(file)
        })
    }

    const cropAndResize = async (img: any) => { // any because the real type is ridiculously long
        const height = img.getHeight()
        const width = img.getWidth()
        if (width !== height) {
            if (width < height) {
                img.crop(0, (height - width) / 2, width, width)
            } else {
                img.crop((width - height) / 2, 0, height, height)
            }
        }
        if (img.getWidth() !== AVATAR_WIDTH_LIMIT) {
            img.resize(AVATAR_WIDTH_LIMIT, AVATAR_HEIGHT_LIMIT)
        }
        return img
    }

    return (
        <>
                <PokerButton
                    onClick={() => {
                        setErrorMessage(null)
                        setShowAvatarDialog(true);
                    }}
                    disabled={false}
                    variant={"contained"}
                    text={intl.formatMessage({id: "avatar_component-text-own-avatar-dialog"})}
                    pokerTheme={pokerTheme}
                />

            <Dialog
                open={showAvatarDialog}
                onClose={handleCancel}
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle>
                    <FormattedMessage id={"avatar_component-text-own-avatar-dialog"} />
                </DialogTitle>
                <DialogContent className={styles.contentContainer}>
                    <Grid
                        container
                        direction={"row"}
                        justify={"space-between"}
                        spacing={2}
                    >
                        <Grid item xs={9}>
                            <Typography>
                                <FormattedMessage
                                    id={"avatar_component-text-dialog_text"}
                                    values={{height: AVATAR_HEIGHT_LIMIT, width: AVATAR_WIDTH_LIMIT}}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Avatar variant={"square"} className={styles.avatarPreview} src={imageLink}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Collapse in={errorMessage !== null}>
                    <Alert onClose={() => setErrorMessage(null)} severity={"error"}>
                        {errorMessage}
                    </Alert>
                </Collapse>
                <DialogActions>
                    <Button
                        id={"avatar_component-button-upload_avatar"}
                        color={'secondary'}
                        variant={'contained'}
                        component={'label'}
                    >
                        <FormattedMessage id={"avatar_component-text-upload_avatar"} />
                        <input
                            accept=".png,.jpeg,.jpg"
                            hidden
                            type="file"
                            onChange={
                                event => event.target.files?.item(0) ? handleUploadAvatar(event) : undefined
                            }
                        />
                    </Button>
                    {imageLink !== defaultAvatar && (
                        <Button
                            id={"avatar_component-button-reset_avatar"}
                            color={'secondary'}
                            variant={'contained'}
                            component={'label'}
                            onClick={handleReset}
                        >
                            <FormattedMessage id={"avatar_component-text-reset_avatar"} />
                        </Button>
                    )}
                    <Button
                        id={"avatar_component-button-save"}
                        color={'primary'}
                        variant={'contained'}
                        disabled={currentAvatar === imageLink}
                        onClick={handleSave}
                    >
                        <FormattedMessage id={"avatar_component-text-save"} />
                    </Button>
                    <Button
                        id={"avatar_component-button-cancel"}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={handleCancel}
                    >
                        <FormattedMessage id={"avatar_component-text-cancel"} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}