import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const pokerTheme = createMuiTheme({
    palette: {
        primary: {main: '#c30a17'},
        secondary: {main: '#3a3E41'},
        poker: {
            main: '#0a893a',
            dark: '#0c622d'
        },
        text: {primary: '#000000'},
        login: {
            main: '#ab1612',
        },
        buttonText: {
            main: '#ffffff',
        }
    },
});

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        poker: Palette['primary'];
        login: Palette['primary'];
        buttonText: Palette['primary'];
    }

    interface PaletteOptions {
        poker: PaletteOptions['primary'];
        login: PaletteOptions['primary'];
        buttonText: PaletteOptions['primary'];
    }
}

export default pokerTheme;