import {
    Button,
    Card,
    CardMedia,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab'
import React, {ChangeEvent, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {customThemeSource, ThemeDefinition, ThemeList, ThemeSource, userThemeSource} from "../../service/ThemeService";
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import RemoveIcon from '@material-ui/icons/Remove';
import JSZip from 'jszip';
import {
    CONFIG_INVALID_ERROR_MESSAGE,
    CONFIG_NOT_FOUND_ERROR_MESSAGE,
    IMAGE_NOT_FOUND_ERROR_MESSAGE,
    PokerThemeProvider,
    ThemeImageLocations,
    Url
} from "../../model/PokerTheme";
import {getImageAsDataUrl} from "../../model/PokerThemeArchives";
import {getCurrentUser, User} from "@2gether/frontend-library";
import ControllerContext from "../../contexts/ControllerContext";
import logger from "../../util/Logger";

interface ThemeChangeDialogProps {
    loadThemes(): void
    showDialog: boolean
    onClose: () => void
    onSubmit: (themeDefinition: ThemeDefinition) => void
    themeList: ThemeList
    currentThemeSource: ThemeSource
    playerID: string
    tableID: string
    customThemes: Map<string, ThemeDefinition>
    setCustomThemes: (cThemes: Map<string, ThemeDefinition>) => void
}
const useStyles = makeStyles(() =>
    createStyles( {
        margin: {
            marginTop: "5%",
            marginRight: "5%",
            marginBottom:"5%",
            width: "90%",
            textAlign: "center"
        },
        input: {
            display: "none",
        },
        themeList: {
            maxHeight: 200,
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        removeIconContainer: {
            margin: 0,
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        removeIconButton: {
            padding: 0,
            color: '#be0000'
        },
        saveProgress: {
            marginLeft: "16px"
        }
    })
);
//type Theme = {name: string, url: string}

const themeDefinitionToKey = (def: ThemeDefinition) => {
    return themeSourceToKey(def.source)
}

const themeSourceToKey = (source: ThemeSource) => {
    return source._tag + ":" + source.name
}

export const ThemeChangeDialog: React.FC<ThemeChangeDialogProps> = ({
    loadThemes,
    themeList,
    currentThemeSource,
    onClose,
    onSubmit,
    showDialog,
    playerID,
    tableID,
    customThemes,
    setCustomThemes
}) => {
    const classes = useStyles();
    const {themeController} = useContext(ControllerContext)
    const [selectedThemeSource, setSelectedThemeSource] = useState(currentThemeSource)
    const [tmpThemeFiles, setTmpThemeFiles] = useState(new Map<string, ThemeDefinition>())
    const [DisplayConfigNotFoundError, setDisplayConfigNotFoundError] = useState(false)
    const [DisplayImageLoadingError, setDisplayImageLoadingError] = useState(false)
    const [DisplayConfigInvalidError, setDisplayConfigInvalidError] = useState(false)
    const [DisplayInvalidFileTypeError, setDisplayInvalidFileTypeError] = useState(false)
    const [DisplayInvalidFileSizeError, setDisplayInvalidFileSizeError] = useState(false)
    const [DisplayUnknownError, setDisplayUnknownError] = useState(false)
    const themeInfoPage = process.env.REACT_APP_THEME_DESCRIPTION_LINK;
    const [themesToDelete, setThemesToDelete] = useState<ThemeSource[]>([])
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        if (showDialog) loadThemes()
    }, [loadThemes, showDialog])

    const persistThemeChanges = async () => {
        return deleteThemes()
            .then(uploadThemes)
            .then(themes => {
                const newThemes = new Map(customThemes);
                themesToDelete.forEach(themeSource => newThemes.delete(themeSource.name))
                themes.forEach(thm => newThemes.set(thm.source.name, thm))
                setCustomThemes(newThemes)
            })
            .catch(err => logger.error(err.message))
    }

    const deleteThemes = async () => {
        return themesToDelete.forEach(theme => {
            themeController.deleteTheme(theme)
        })
    }

    const uploadThemes = async () => {
        let cognitoID: string | undefined = undefined
        try {
            const user = await getCurrentUser()
            if (user.isAuthorized) {
                cognitoID = user.username
            }
        } catch (err) {
        } finally {
            return Promise.all([...tmpThemeFiles.entries()].map(async ([k, v]) => {
                const themeSource = cognitoID ? userThemeSource(cognitoID, k) : customThemeSource(tableID, playerID, k)
                return themeController.uploadTheme(themeSource, v.file).then(() => {
                    return {
                        source: themeSource,
                        file: v.file,
                        preview: v.preview
                    }
                })
            }))
        }
    }

    const themesBySource: { [key: string]: ThemeDefinition } = useMemo(() => {
        const list1 = Object.entries(themeList.themes).map(([name, theme]) => [themeDefinitionToKey(theme), {...theme, name}])
        const list2 = [...customThemes.entries()].map(([name, theme]) => [themeDefinitionToKey(theme), {...theme, name}])
        const list3 = [...tmpThemeFiles.entries()].map(([name, theme]) => [themeDefinitionToKey(theme), {...theme, name}])
        return Object.fromEntries(list1.concat(list2).concat(list3))
    }, [themeList, customThemes, tmpThemeFiles])

    const selectedTheme = useMemo(() => themesBySource[themeSourceToKey(selectedThemeSource)], [selectedThemeSource, themesBySource])

    const setSelectedThemeToCurrentTheme = useCallback(() => {
        if (themesBySource[themeSourceToKey(currentThemeSource)] !== undefined
            && !themesToDelete.includes(currentThemeSource)) {
            setSelectedThemeSource(currentThemeSource)
        }
        else {
            setSelectedThemeSource(themeList.themes[themeList.default].source)
        }
    }, [themesBySource, setSelectedThemeSource, themeList, themesToDelete, currentThemeSource])

    const addCustomThemeFile = async (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.files) {
            return
        }
        const file = event.target.files[0]
        event.target.value = "" // required to reset chrome's input for same file selection after reset
        const fileName = file.name.replace(/\.[^/.]+$/, "")
        let newName = fileName
        if(file.type !== 'application/zip' && file.type !== "application/x-zip-compressed") {
            setDisplayInvalidFileTypeError(true)
            return
        }
        if(file.size >= 4000000) {
            setDisplayInvalidFileSizeError(true)
            return
        }

        const definition = {
            file: file,
            source: customThemeSource(tableID, playerID, newName),
            preview: ""
        }
        let user: User | undefined
        try {
            user = await getCurrentUser()
        } catch (err) {
        } finally {

            await PokerThemeProvider.fetchTheme(definition)
                .then(thm => PokerThemeProvider.validateTheme(thm))
                .then(() =>
                    new JSZip().loadAsync(file)
                        .then(async (zipFile) => {
                            let i = 1;
                            while (tmpThemeFiles.has(newName)
                            || customThemes.has(newName)
                            || themeList.themes.hasOwnProperty(newName)) {
                                newName = fileName + "(" + i++ + ")"
                            }
                            const image = await getImageAsDataUrl(zipFile, Url(ThemeImageLocations.PREVIEW))
                            const source = user && user.isAuthorized ? userThemeSource(user.username, newName) : customThemeSource(tableID, playerID, newName)
                            setTmpThemeFiles(new Map(tmpThemeFiles)
                                .set(newName, {
                                    file: file,
                                    source: source,
                                    preview: image
                                }))
                            setSelectedThemeSource(source)
                            return
                        })
                )
                .catch((err) => {
                    if(err.message === CONFIG_NOT_FOUND_ERROR_MESSAGE) setDisplayConfigNotFoundError(true)
                    else if(err.message === IMAGE_NOT_FOUND_ERROR_MESSAGE) setDisplayImageLoadingError(true)
                    else if(err.message === CONFIG_INVALID_ERROR_MESSAGE) setDisplayConfigInvalidError(true)
                    else {
                        setDisplayUnknownError(true)
                        logger.error(err.message)
                    }
                })
        }

    }

    function renderErrors(): JSX.Element {
        return <Snackbar open={true}>

            <Alert severity={"error"}
                   variant={"filled"}
                   onClose={resetErrors}>
                <AlertTitle><FormattedMessage  id={"theme_error-header"}/></AlertTitle>
                {DisplayConfigNotFoundError && <p><FormattedMessage  id={"theme_error-config-not-found"}/></p>}
                {DisplayImageLoadingError && <p><FormattedMessage  id={"theme_error-image-loading-issue"}/></p>}
                {DisplayConfigInvalidError && <p><FormattedMessage  id={"theme_error-invalid-config"}/></p>}
                {DisplayInvalidFileTypeError && <p><FormattedMessage  id={"theme_error-invalid-file-type"}/></p>}
                {DisplayInvalidFileSizeError && <p><FormattedMessage  id={"theme_error-invalid-file-size"}/></p>}
                {DisplayUnknownError && <p><FormattedMessage  id={"theme_error-unknown"}/></p>}
            </Alert>
        </Snackbar>
    }

    const resetErrors = () => {
        setDisplayConfigNotFoundError(false)
        setDisplayImageLoadingError(false)
        setDisplayConfigInvalidError(false)
        setDisplayInvalidFileTypeError(false)
        setDisplayInvalidFileSizeError(false)
        setDisplayUnknownError(false)
    }

    const themeChange = (event: ChangeEvent<{}>) => {
        setSelectedThemeSource(themesBySource[(event.currentTarget as HTMLInputElement).value].source);
    }

    const truncateText = ( text: string, length: number ): string => {
        if (text.length <= length) { return text; }
        const subString = text.substr(0, length-1);
        return subString + "...";
    };

    const handleRemoveTheme = (source: ThemeSource) => {
        if(tmpThemeFiles.get(source.name) === undefined){
            setThemesToDelete([...themesToDelete, source])
        } else {
            const newFiles = new Map(tmpThemeFiles)
            newFiles.delete(source.name)
            setTmpThemeFiles(newFiles)
        }
        if (selectedThemeSource.name === source.name)
            setSelectedThemeSource(themeList.themes[themeList.default].source)
    }

    const handleSave = async  () => {
        setUploading(true)
        persistThemeChanges()
            .then(() => {
                onSubmit(themesBySource[themeSourceToKey(selectedThemeSource)])
                setThemesToDelete([])
                setTmpThemeFiles(new Map())
                setUploading(false)
            })
            .catch(err => {
                logger.error(err.message)
                setDisplayUnknownError(true)
                setUploading(false)
            })

    }

    const renderCustomThemeList = () => {
        return (
            <div>
                {[...customThemes.entries(), ...tmpThemeFiles.entries()]
                    .filter(([, theme]) => !themesToDelete.includes(theme.source))
                    .map(([name, theme], index) => {
                    return (
                        <Grid key={`${name}-${index}`} container spacing={1}>
                            <Grid item xs={10}>
                                <FormControlLabel
                                key={`${name}-${index}`}
                                control={<Radio />}
                                value={themeDefinitionToKey(theme)}
                                label={
                                    <Tooltip title={name}>
                                        <div>
                                            {truncateText(name, 18)}
                                        </div>
                                    </Tooltip>
                                }
                            />
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.removeIconContainer}>
                                    <Tooltip title="Löschen">
                                        <IconButton className={classes.removeIconButton}
                                                    onClick={() => {
                                                        handleRemoveTheme(theme.source)
                                                    }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    );
                })}
            </div>
        );
    }

    const renderThemeList = () => {
        return (
            <div className={classes.themeList}>
                <RadioGroup value={themeSourceToKey(selectedThemeSource)} onChange={themeChange}>
                    {Object.entries(themeList.themes).map(
                        ([themeName, theme], index) => {
                        return (
                            <FormControlLabel key={index} value={themeDefinitionToKey(theme)} control={<Radio />} label={themeName} />
                        );
                    })}
                    <Divider/>
                    {renderCustomThemeList()}
                </RadioGroup>
            </div>
        );
    }

    const renderThemePreview = () => {
        return (
            <Card>
                <CardMedia
                    src = {selectedTheme?.preview}
                    component = "img"
                    title = {selectedTheme?.source.name}
                />
            </Card>
        );
    }

    useEffect(() => {
        setSelectedThemeToCurrentTheme()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog])   //forces the Dialog to select the current theme when opened,
                            // don't add setSelectedThemeToCurrentTheme as dependency,
                            // this is supposed to trigger only on opening the dialog (and closing it)

    return (
        <Dialog
            open={showDialog}
            onClose={() => {
                onClose()
                resetErrors()
                setSelectedThemeSource(currentThemeSource)
            }}
            maxWidth={"md"}
            fullWidth={true}>
            <DialogTitle>
                <FormattedMessage id={"theme_change_dialog-text-title"}/>
            </DialogTitle>
            <DialogContent>
                <Grid container wrap={"nowrap"} direction={"row"} alignItems={"center"} justify={"space-between"}>
                    <Grid xs={3} item>
                        {renderThemeList()}
                        <input
                            accept=".zip"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={addCustomThemeFile}
                        />
                        <label htmlFor="contained-button-file">
                            <Button className={classes.margin}
                                    color={"default"}
                                    size={"medium"}
                                    variant={"contained"}
                                    component="span">
                                <FormattedMessage  id={"theme_change_dialog-own-theme-upload"}/>
                            </Button>
                        </label>
                        <p><a href={themeInfoPage} target="_blank" rel="noreferrer"><FormattedMessage  id={"theme_change_dialog-info-about-theme"}/></a></p>
                    </Grid>
                    <Grid xs={9} item>
                        {renderThemePreview()}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"}
                        disabled={uploading}
                        variant={"contained"}
                        onClick={handleSave}
                >
                    <FormattedMessage id={"theme_change_dialog-text-save"}/>
                    {uploading ? <CircularProgress size={15} thickness={5} className={classes.saveProgress}/> : null}
                </Button>
                <Button color={"secondary"}
                        disabled={uploading}
                        variant={"contained"}
                        onClick={() => {
                            onClose();
                            resetErrors()
                            setTmpThemeFiles(new Map())
                            setThemesToDelete([])
                            setSelectedThemeToCurrentTheme()
                        }}>
                    <FormattedMessage id={"theme_change_dialog-text-cancel"}/>
                </Button>
            </DialogActions>
            { (DisplayConfigNotFoundError
                || DisplayImageLoadingError
                || DisplayConfigInvalidError
                || DisplayInvalidFileTypeError
                || DisplayInvalidFileSizeError
                || DisplayUnknownError)
            && renderErrors()}
        </Dialog>
    );
}