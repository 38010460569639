import React, {useMemo} from "react";
import {FibonacciCardValues, PokerCardValue} from "../../model/PokerCard";
import CardButton from "../Cards/CardButton";
import MainController from "../../controller/control/MainController";
import PokerPlayer from "../../model/PokerPlayer";
import PokerTable, {VotingType} from "../../model/PokerTable";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {FreeNumberComponent} from "./FreeNumberComponent";
import {PokerTheme} from "../../model/PokerTheme";
import {cardShouldBeClickable} from "../../util/Cards";
import {isNaN} from "mathjs";

const useStyles = makeStyles((theme: Theme) => ({
    deck: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: "40px",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {marginBottom: "10px"},
        [theme.breakpoints.down("sm")]: {margin: "auto", maxWidth: "300px"}
    },
}))

interface CardComponentProps {
    table: PokerTable
    tableID: string
    controller: MainController
    localPlayer: PokerPlayer,
    selectedCardID: PokerCardValue | null,
    setSelectedCardID: (id: PokerCardValue | null) => void,
    pokerTheme: PokerTheme
}

export const CardComponent: React.FC<CardComponentProps> = (props) => {
    const classes = useStyles()
    const {
        table,
        tableID,
        controller,
        localPlayer,
        selectedCardID,
        setSelectedCardID,
        pokerTheme
    } = props

    const getCardValues = () => {
        switch (table.votingType) {
            case VotingType.FIBONACCI:
                return FibonacciCardValues
            case VotingType.FREE_VOTE:
                let values: PokerCardValue[] = []
                for (let index of table.votingOptionsIndex) {
                    if (isNaN(parseInt(index))) {
                        values.push(index);
                    } else {
                        values.push(parseInt(index) * 100);
                    }
                }
                return values;
            case VotingType.FREE_NUMBER:
                return []
        }
    }

    const cardButtons = useMemo(
        () => {
            const deck = getCardValues();
            let playerCardId = localPlayer.playedCardValue ? localPlayer.playedCardValue : null;
            return deck.map((value, index) => {
                const cardID = value;
                return (
                    <CardButton
                        key={cardID}
                        cardID={cardID}
                        tableID={tableID}
                        selected={selectedCardID === cardID || playerCardId === cardID}
                        cardValue={value}
                        controller={controller}
                        onSelect={event => setSelectedCardID(event)}
                        tooltipText={table.votingType === VotingType.FREE_VOTE ? table.votingOptions[index] : ""}
                        typeSubmit={false}
                        pokerTheme={pokerTheme}
                        disabled={!cardShouldBeClickable(table, localPlayer)}
                        id={`card_component-card_button-${index}`}
                    />
                );
            });
        },
        [table, tableID, controller, localPlayer, selectedCardID, setSelectedCardID, pokerTheme]
    )

    if (props.table.votingType === VotingType.FREE_VOTE) {
        return null
    }

    if (props.table.votingType === VotingType.FREE_NUMBER) {
        const freeNumberConstraints = (value: string) => {
            const onlyNumbersWithTwoDigits = /^\s*-?[0-9]\d*(\.\d{0,2})?\s*$/;
            const numString = value.replace(",", ".")
            const num = Number(numString)
            return num >= 0 && num <= 1000 && onlyNumbersWithTwoDigits.test(numString)
        }

        return <FreeNumberComponent table={table} tableID={tableID} controller={controller} localPlayer={localPlayer}
                                    pokerTheme={pokerTheme} constraints={freeNumberConstraints}/>
    }

    return <div className={classes.deck}>
        {cardButtons}
    </div>
}
