import React, {useContext} from "react";
import PokerTable, {VotingType} from "../../model/PokerTable";
import {cardValueToString} from "../Cards/Card";
import PokerPlayer, {Role} from "../../model/PokerPlayer";
import {Grid, makeStyles} from "@material-ui/core";
import {nanoid} from "nanoid";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {PokerButton} from "./PokerButton";
import {cardShouldBeClickable} from "../../util/Cards";
import {ChartComponent} from "../PokerTable/ChartComponent";
import {FormattedMessage, useIntl} from "react-intl";
import {PokerCardValue} from "../../model/PokerCard";
import logger from "../../util/Logger";
import ControllerContext from "../../contexts/ControllerContext";

interface Props {
    pokerTable: PokerTable,
    selfPlayer: PokerPlayer;
    playCard: (value: PokerCardValue, playedBy: string|undefined) => void;
    pokerTheme: PokerTheme
}

const useStyle = makeStyles((theme) => ({
    tableContainer: {
        width: "100%",
        height: "100%",
        padding: "2rem"
    },
    tableContent: (pTheme: PokerTheme) => ({
        minHeight: "300px",
        width: "70%",
        boxShadow: "0 0 10px",
        backgroundColor: asCssRGBAString(pTheme.panel.background),
        margin: "10px",
        textAlign: "center",
        padding: "10px",
    }),
    playerCell: {
        height: "100%"
    },
    chartDiv: {
        width: "70%",
    }
}));

export const ReducedPokerTableComponent: React.FC<Props> = (props) => {
    const {pokerTable, selfPlayer, playCard, pokerTheme} = props;
    const tableStyles = useStyle(pokerTheme)
    const {mainController} = useContext(ControllerContext)
    const intl = useIntl()

    const renderPlayerEntry = (player: PokerPlayer) => {
        const hasPlayedCard =
            (player.playedCardValue !== null && player.playedCardValue !== undefined)

        const participatesInVote =
            player.connected && player.role === Role.VOTER

        if (! hasPlayedCard && ! participatesInVote)
            return null;

        let cardText = "";
        if (pokerTable.isVotingEnabled() && hasPlayedCard)
            if (pokerTable.hasCardsRevealed())
                cardText = cardValueToString(player.playedCardValue!!)
            else
                cardText = intl.formatMessage({id: "poker_table_mobile_component-text-not_revealed"})

        const isClickable = (value: PokerCardValue | undefined): boolean => {
            return (
                cardShouldBeClickable(pokerTable, selfPlayer)
                && pokerTable.hasCardsRevealed()
                && value !== undefined
                && value !== selfPlayer.playedCardValue);
        }

        const onClick = (value: PokerCardValue | undefined, id: string|undefined) => {
            if (value !== undefined && value !== selfPlayer.playedCardValue)
                playCard(value, id);
        }

        const onClickSwitchPlayerRoleByAdmin = () => {
            if (selfPlayer.canControlTable(pokerTable)) {
                mainController.switchPlayerRoleByAdmin(pokerTable.id, player).catch(err => logger.error(err));
            }
        }

        return (
            <Grid item key={nanoid()}>
                <Grid className={tableStyles.playerCell} container direction={"column"} justify={"flex-end"} wrap={"nowrap"} spacing={1}>
                    <Grid item onClick={() => onClickSwitchPlayerRoleByAdmin()}
                          style={ selfPlayer.canControlTable(pokerTable)? {cursor: "pointer"} : {} }>
                       {player.connected && player.role === Role.VOTER ? player.name : ""}
                    </Grid>
                    <Grid item>
                        {cardText !== "" ?
                            <PokerButton
                                variant={"contained"}
                                pokerTheme={pokerTheme}
                                onClick={() => onClick(player.playedCardValue, player.id)}
                                disabled={! isClickable(player.playedCardValue)}
                                text={cardText}>

                            </PokerButton> :
                            null
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container justify={"center"} alignItems={"center"} className={tableStyles.tableContainer}>
            <Grid
                item
                container
                direction={"column"}
                justify={"space-between"}
                alignItems={"center"}
                spacing={2}
                className={tableStyles.tableContent}>
                <Grid item>
                    <h2><FormattedMessage id={"poker_table_mobile_component-text-table"}/></h2>
                </Grid>
                <Grid
                    item
                    container
                    spacing={3}
                    direction={"row"}
                    alignItems={"stretch"}
                    justify={"center"}
                >
                    {
                        pokerTable.getPlayersAtTable().map((voter: PokerPlayer) => (
                            renderPlayerEntry(voter)
                        ))
                    }
                </Grid>
                <Grid item className={tableStyles.chartDiv}>
                    {
                        (pokerTable.hasCardsRevealed() && !(pokerTable.votingType === VotingType.FREE_VOTE))
                        && <ChartComponent table={pokerTable} pokerTheme={pokerTheme}/>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ReducedPokerTableComponent
