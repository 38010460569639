import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    RadioGroup,
} from '@material-ui/core';
import React, {ChangeEvent, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
import {VotingOptionsFilters} from '../../enums/VotingOptionsFilters';
import RepeatFreeVoteOption from "./RepeatFreeVoteOption";
import {getVotingOptionsForLastFreeVote, VotingOptionsWithIndex} from "../../service/RepeatFreeVoteFilterService";
import logger from "../../util/Logger"
import {HistoryItem} from "../../pages/PokerPage";

interface IRepeatFreeVoteDialog {
    lastFreeVoteFromHistory: HistoryItem
    showRepeatFreeVoteDialog: boolean;
    closeRepeatFreeVoteDialog: () => void;
    startFreeVote: (votingOptions: string[], indices: string[]) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        optionsContainer: {
            padding: '10px',
            width: '90%',
            left: '5%',
        },
        cancelButton: {
            marginRight: 5,
        },
    })
);

export const RepeatFreeVoteDialog: React.FC<IRepeatFreeVoteDialog> = ({
                                                                          lastFreeVoteFromHistory,
                                                                          showRepeatFreeVoteDialog,
                                                                          closeRepeatFreeVoteDialog,
                                                                          startFreeVote,
                                                                      }) => {
    const classes = useStyles();
    const [value, setValue] = useState('All');
    const [votingOptionsFilter, setVotingOptionsFilter] = useState(
        VotingOptionsFilters.ALL
    );
    const intl = useIntl()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const cancel = (): void => {
        closeRepeatFreeVoteDialog();
    };

    const votingOptionsForAllFilters = useMemo(() => {
            return getVotingOptionsForLastFreeVote(lastFreeVoteFromHistory)
        }, [lastFreeVoteFromHistory]
    )


    const getVotingOptionsForFilter = (filter: VotingOptionsFilters): VotingOptionsWithIndex => {
        if (!votingOptionsForAllFilters) {
            return new VotingOptionsWithIndex();
        }
        let optionsForSelectedFilter = votingOptionsForAllFilters.get(filter)
        if (optionsForSelectedFilter === undefined) {
            logger.error("Selected filter not found.")
            return new VotingOptionsWithIndex(lastFreeVoteFromHistory.votingOptions, lastFreeVoteFromHistory.indexForVotingOptions);
        }
        return optionsForSelectedFilter
    }

    const startVote = (): void => {
        startFreeVote(getVotingOptionsForFilter(votingOptionsFilter).votingOptions, getVotingOptionsForFilter(votingOptionsFilter).indices);
        closeRepeatFreeVoteDialog();
    };

    const renderList = () => {
        return (
            <FormControl component='fieldset' className={classes.optionsContainer}>
                <RadioGroup value={value} onChange={handleChange}>
                    <RepeatFreeVoteOption
                        value={'All'}
                        label={intl.formatMessage({id: 'repeat_free_vote_dialog-option_all'})}
                        numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.ALL).votingOptions.length}
                        onChange={() => setVotingOptionsFilter(VotingOptionsFilters.ALL)}/>
                    <RepeatFreeVoteOption
                        value={'Top 60%'}
                        label={'Top 60%'}
                        numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_SIXTY_PERCENT).votingOptions.length}
                        onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_SIXTY_PERCENT)}/>
                    <RepeatFreeVoteOption
                        value={'Top 50%'}
                        label={'Top 50%'}
                        numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_FIFTY_PERCENT).votingOptions.length}
                        onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_FIFTY_PERCENT)}/>
                    <RepeatFreeVoteOption
                        value={'Top 40%'}
                        label={'Top 40%'}
                        numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_FORTY_PERCENT).votingOptions.length}
                        onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_FORTY_PERCENT)}/>
                    <RepeatFreeVoteOption
                        value={'Top 30%'}
                        label={'Top 30%'}
                        numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_THIRTY_PERCENT).votingOptions.length}
                        onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_THIRTY_PERCENT)}/>
                    {votingOptionsForAllFilters && votingOptionsForAllFilters.has(VotingOptionsFilters.TOP_FIVE) && (
                        <RepeatFreeVoteOption
                            value={'Top 5'}
                            label={'Top 5'}
                            numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_FIVE).votingOptions.length}
                            onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_FIVE)}/>
                    )}
                    {votingOptionsForAllFilters && votingOptionsForAllFilters.has(VotingOptionsFilters.TOP_FOUR) && (
                        <RepeatFreeVoteOption
                            value={'Top 4'}
                            label={'Top 4'}
                            numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_FOUR).votingOptions.length}
                            onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_FOUR)}/>
                    )}
                    {votingOptionsForAllFilters && votingOptionsForAllFilters.has(VotingOptionsFilters.TOP_THREE) && (
                        <RepeatFreeVoteOption
                            value={'Top 3'}
                            label={'Top 3'}
                            numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_THREE).votingOptions.length}
                            onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_THREE)}/>
                    )}
                    {votingOptionsForAllFilters && votingOptionsForAllFilters.has(VotingOptionsFilters.TOP_TWO) && (
                        <RepeatFreeVoteOption
                            value={'Top 2'}
                            label={'Top 2'}
                            numberOfOptions={getVotingOptionsForFilter(VotingOptionsFilters.TOP_TWO).votingOptions.length}
                            onChange={() => setVotingOptionsFilter(VotingOptionsFilters.TOP_TWO)}/>
                    )}
                </RadioGroup>
            </FormControl>
        );
    };

    const renderButtons = () => {
        return (
            <>
                <Button
                    id={'free_vote_dialog-button-start'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={startVote}
                >
                    <FormattedMessage id={'free_vote_dialog-text-start'}/>
                </Button>
                <Button
                    id={'free_vote_dialog-button-cancel'}
                    color={'secondary'}
                    variant={'contained'}
                    className={classes.cancelButton}
                    onClick={cancel}
                >
                    <FormattedMessage id={'free_vote_dialog-text-cancel'}/>
                </Button>
            </>
        );
    };

    return (
        <Dialog
            open={showRepeatFreeVoteDialog}
            onClose={cancel}
            maxWidth={'xs'}
            fullWidth={true}
            disableBackdropClick={true}
        >
            <DialogTitle>
                <FormattedMessage id={'repeat_free_vote_dialog-title'}/>
            </DialogTitle>
            <DialogContent>{renderList()}</DialogContent>
            <DialogActions>{renderButtons()}</DialogActions>
        </Dialog>
    );
};

export default RepeatFreeVoteDialog;
